import { Box, Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AdjustIcon from "@mui/icons-material/Adjust";
import React from "react";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";

const OrderCard = ({ item, order }) => {
  const navigate = useNavigate();
  console.log("items ", item);
  return (
    <Box className="p-5 shadow-lg hover:shadow-2xl border ">
      <Grid spacing={2} container sx={{ justifyContent: "space-between" }}>
        <Grid item xl={6} md={6} xs={12} >
          <div
            onClick={() => navigate(`/account/order/${order?._id}`)}
            className="flex cursor-pointer"
          >
            <img
              className="w-[5rem] h-[5rem] object-cover object-top"
              src={item?.product?.imageUrl}
              alt=""
            />
            <div className="ml-5">
              <p className="mb-2 font-medium">{item?.product?.title}</p>
              <p className="opacity-50 text-xs font-semibold space-x-5">
                <span>Size: {item?.size}</span>
              </p>
            </div>
          </div>
        </Grid>

        <Grid item xl={2}  md={2} xs={12} >
          <p className="font-medium">₹{item?.price}</p>
        </Grid>
        <Grid item xl={4} md={4} xs={12} >
          <p className="space-y-2 font-semibold">
            {/* {order?.orderStatus === "DELIVERED"? (
             <>
             <FiberManualRecordIcon
                  sx={{ width: "15px", height: "15px" }}
                  className="text-green-600 p-0 mr-2 text-sm"
                />
                <span>Delivered On Mar 03</span>

            </>
            ):  <> */}
               
                <AdjustIcon
                sx={{ width: "15px", height: "15px" }}
                className="text-green-600 p-0 mr-2 text-sm"
              />
              <span>
                {
                  order?.orderStatus === "PLACED" ? "Order Confirmed" : 
                  order?.orderStatus === "CONFIRMED" ? "SHIPPED" : 
                  order?.orderStatus === "SHIPPED" ? "OUT FOR DELIVERY" :
                  order?.orderStatus === "DELIVERED" ? "DELIVERED" : order?.orderStatus
                }
              </span>
              {/* <span>Expected Delivery On Mar 03</span>
              </>} */}
            
          </p>
          {/* <p className="text-xs">Your Item Has Been Delivered</p> */}
          {item.orderStatus === "DELIVERED" && (
            <div
              onClick={() => navigate(`/account/rate/{id}`)}
              className="flex items-center text-blue-600 cursor-pointer"
            >
              <StarIcon sx={{ fontSize: "2rem" }} className="px-2 text-5xl" />
              <span>Rate & Review Product</span>
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderCard;
