// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB6XUqb_HvnAsHllu01mNOk6gl8Qi8Z9X0",
    authDomain: "aaba-g-bucket.firebaseapp.com",
    projectId: "aaba-g-bucket",
    storageBucket: "aaba-g-bucket.appspot.com",
    messagingSenderId: "189023110392",
    appId: "1:189023110392:web:9240b8540ffae1a472e70d"
  };

const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(firebaseApp);

export { storage };






// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyB6XUqb_HvnAsHllu01mNOk6gl8Qi8Z9X0",
//   authDomain: "aaba-g-bucket.firebaseapp.com",
//   projectId: "aaba-g-bucket",
//   storageBucket: "aaba-g-bucket.appspot.com",
//   messagingSenderId: "189023110392",
//   appId: "1:189023110392:web:9240b8540ffae1a472e70d"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);