// UnauthorizedPage.js
import React from 'react';

const UnauthorizedPage = () => {
    return (
        <div className='flex flex-col h-[100vh] items-center justify-center'>
            <div className='flex items-center'>
                <span style={{ fontSize: '3rem', fontWeight: 800, color: '#f14b24' }}>!</span>
                <span className='px-2' style={{fontSize: '2rem', color: '#f14b24', fontWeight: '600' }}>Access Denied</span>
            </div>
            <p style={{ fontSize: '1.3rem', fontWeight: '600' }}>You do not have permission to view this page.</p>
        </div>
    );
};

export default UnauthorizedPage;
