import React from "react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { removeWishlistItem } from "../../../Redux/Customers/Wishlist/Action";
import DeleteIcon from '@mui/icons-material/Delete';
import{useLocation, useNavigate} from "react-router-dom";

const WishlistItem = ({ item,showButton }) => {
  const dispatch = useDispatch();
  const navigate= useNavigate();

  const jwt = localStorage.getItem("jwt");

  const handleRemoveItemFromWishlist = () => {
    const data = { wishlistItemId: item?._id, jwt };
    dispatch(removeWishlistItem(data));
  };
  const handleNavigate=()=>{
    navigate(`/product/${item?.product?._id}`)
  }
  
  return (
    <div className="p-5 shadow-lg border rounded-md flex justify-between">
      <div className="flex items-center cursor-pointer" onClick={handleNavigate}>
        <div className="w-[5rem] h-[5rem] lg:w-[9rem] lg:h-[9rem] ">
          <img
            className="w-full h-full object-cover object-top"
            src={item?.product?.imageUrl}
            alt=""
          />
        </div>
        <div className="ml-5 space-y-1">
          <p className="font-semibold">{item?.product?.title}</p>
          <p className="opacity-70">Size: {item?.size},{item?.product?.color}</p>
          <p className="opacity-70 mt-2">Seller: {item?.product?.brand}</p>
          <div className="flex space-x-2 items-center pt-3">
            <p className="opacity-50 line-through">₹{item?.product?.price}</p>
            <p className="font-semibold text-lg">
              ₹{item?.product?.discountedPrice}
            </p>
            <p className="text-green-600 font-semibold">
              {item?.product?.discountPersent}% off
            </p>
          </div>
        </div>
      </div>
     {showButton&& <div className="lg:flex items-center lg:space-x-10 pt-4">
        <div className="flex text-sm lg:text-base mt-5 lg:mt-0" style={{color:"#c7c7c7"}}>
          <DeleteIcon onClick={handleRemoveItemFromWishlist} variant="text"></DeleteIcon>
        </div>
      </div>}
    </div>
  );
};

export default WishlistItem;
