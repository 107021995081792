import React from "react";
import WishlistItem from "./WishlistItem";
import { Badge, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getWishlist } from "../../../Redux/Customers/Wishlist/Action";

const Wishlist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwt = localStorage.getItem("jwt");
  const {wishlist,wishlistItems}=useSelector(store=>store);

//   useEffect(() => {
//     dispatch(getWishlist(jwt));
//   }, [jwt]);
  useEffect(() => {
    dispatch(getWishlist(jwt));
  },[]);
  return (
    <div className="">
    {console.log(wishlist)}

        {wishlist?.wishlistItems?.length > 0 && (
            <div className="lg:px-16 relative">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 bg-white lg:px-5">
                    <div className="space-y-3 col-span-full">
                        {wishlist?.wishlistItems?.map((item) => (
                            <WishlistItem key={item?.id} item={item} showButton={true} />
                        ))}
                    </div>
                </div>
            </div>
        )}
    </div>
  );
};

export default Wishlist;
