export const navigation = {
  categories: [
    {
      id: 'women',
      name: "Women's",
      featured: [
        {
          name: 'New Arrivals',
          href: '/',
          imageSrc: 'https://i.postimg.cc/ydbGMcCc/2-6ba112bc-dac3-438a-9ec8-2114d684c040-800x1056.webp',
          imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
        },
        {
          name: 'Basic Tees',
          href: '/',
          imageSrc: 'https://i.postimg.cc/44K4m1yC/Whats-App-Image2021-12-12at9-33-30-PM-1.jpg',
          imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
        },
      ],
      sections: [
        {
          id: 'clothing',
          name: 'Clothing',
          items: [
            // { name: 'Kurtie', id: "womens_kurtie" },
            // { name: 'Top Set', id: "womens_top_set" },
            { name: 'Saree', id: 'womens_saree' },
            { name: 'Nightie', id: "womens_nightie" },
            // { name: 'Saree', id: "womens_saree" },
            { name: 'Churidar Material', id: "womens_churidar_material" },
            { name: 'Kurta Set', id: "womens_kurta_set" },
            { name: 'Kurta', id: "womens_kurta" },
            { name: 'Bottom Wears', id: "womens_bottom_wears" },
            { name: 'Dupatta', id: "womens_dupatta" },
            { name: 'Bra', id: "womens_bra" },
            { name: 'Panties', id: "womens_panties" },
            { name: 'Camisole (Slip)', id: "womens_camisole" },
            { name: 'Jeans Top', id: "womens_jeans_top" },
            { name: 'Jeans', id: "womens_jeans" },
          ],
        },
      ],
    },
    {
      id: 'men',
      name: "Men's",
      featured: [
        {
          name: 'New Arrivals',
          id: '#',
          imageSrc: 'https://i.postimg.cc/L5fMvNgn/mens-wear-1000x1000.webp',
          imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
        },
        {
          name: 'Artwork Tees',
          id: '#',
          imageSrc: 'https://i.postimg.cc/44hZJN2s/pri-175-p-1622893938.webp',
          imageAlt: 'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
        },
      ],
      sections: [
        {
          id: 'clothing',
          name: 'Clothing',
          items: [
            { name: 'Dhotie', id: 'mens_dhotie' },
            { name: 'Lungie', id: 'mens_lungie' },
            { name: 'Shirts', id: 'mens_shirts' },
            { name: 'T-Shirts', id: 'mens_t_shirts' },
            { name: 'Trunk', id: 'mens_trunk' },
            { name: 'Brief', id: 'mens_brief' },
            { name: 'Vest', id: 'mens_vest' },
          ],
        },
      ],
    },
    {
      id: 'kid',
      name: "Kid's",
      featured: [
        {
          name: 'New Arrivals',
          id: '#',
          imageSrc: 'https://cdn.fcglcdn.com/brainbees/images/products/583x720/17150244a.webp',
          imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
        },
        {
          name: 'Artwork Tees',
          id: '#',
          imageSrc: 'https://cdn.fcglcdn.com/brainbees/images/products/583x720/18117456a.webp',
          imageAlt: 'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
        },
      ],
      sections: [
        {
          id: 'clothing',
          name: 'Clothing',
          items: [
            // { name: 'Shirts', id: 'kids_shirts' },
            // { name: 'T-Shirt', id: 'kids_t_shirt' },
            // { name: 'Pants', id: 'kids_pants' },
            // { name: 'Shorts', id: 'kids_shorts' },
            { name: 'New Born', id: 'kids_new_born' },
            { name: 'Boy', id: 'kids_boy' },
            { name: 'Girl', id: 'kids_girl' },
            { name: 'New Born Gift Hamper Box', id: 'kids_new_born_gift_hamper_box' },
          ],
        },
      ],
    },
  ],
  pages: [
    // { name: 'Company', id: '/' },
    // { name: 'Stores', id: '/' },
  ],
};

























// export const navigation = {
//     categories: [
//       {
//         id: 'women',
//         name: "Women's",
//         featured: [
//           {
//             name: 'New Arrivals',
//             href: '/',
//             imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg',
//             imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
//           },
//           {
//             name: 'Basic Tees',
//             href: '/',
//             imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg',
//             imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
//           },
//         ],
//         sections: [
//           {
//             id: 'clothing',
//             name: 'Clothing',
//             items: [
//               { name: 'Kurtie', id:"womens_kurtie" },
//               { name: 'Top Set', id:"womens_top_set" },
//               { name: 'Saree', id: 'womens_saree' },
//               { name: 'Nightie', id:"womens_nightie" },
//             ],
//           },
//         ],
//       },
//       {
//         id: 'men',
//         name: "Men's",
//         featured: [
//           {
//             name: 'New Arrivals',
//             id: '#',
//             imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg',
//             imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
//           },
//           {
//             name: 'Artwork Tees',
//             id: '#',
//             imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg',
//             imageAlt:
//               'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
//           },
//         ],
//         sections: [
//           {
//             id: 'clothing',
//             name: 'Clothing',
//             items: [
//               { name: 'Dhotie', id: 'mens_dhotie' },
//               { name: 'Lungie', id: 'mens_kurta' },
//               { name: 'Brief', id: 'mens_brief' },
//               { name: 'Vest', id: 'mens_vest' },
              
//             ],
//           },
//         ],
//       },
//       {
//         id: 'kid',
//         name: "Kid's",
//         featured: [
//           {
//             name: 'New Arrivals',
//             id: '#',
//             imageSrc: 'https://cdn.fcglcdn.com/brainbees/images/products/583x720/17150244a.webp',
//             imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
//           },
//           {
//             name: 'Artwork Tees',
//             id: '#',
//             imageSrc: 'https://cdn.fcglcdn.com/brainbees/images/products/583x720/18117456a.webp',
//             imageAlt:
//               'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
//           },
//         ],
//         sections: [
//           {
//             id: 'clothing',
//             name: 'Clothing',
//             items: [
//               { name: 'Shirts', id: 'kids_shirts' },
//               { name: 'T-Shirt', id: 'kids_t-shirt' },
//               { name: 'Pants', id: 'kids_pants' },
//               { name: 'Shorts', id: 'kids_shorts' },
              
//             ],
//           }
//         ],
//       },
//     ],
//     pages: [
//       // { name: 'Company', id: '/' },
//       // { name: 'Stores', id: '/' },
//     ],
//   }






























  // export const navigation = {
  //   categories: [
  //     {
  //       id: 'women',
  //       name: 'Women',
  //       featured: [
  //         {
  //           name: 'New Arrivals',
  //           href: '/',
  //           imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg',
  //           imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
  //         },
  //         {
  //           name: 'Basic Tees',
  //           href: '/',
  //           imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg',
  //           imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
  //         },
  //       ],
  //       sections: [
  //         {
  //           id: 'clothing',
  //           name: 'Clothing',
  //           items: [
  //             { name: 'Tops', id:"top", href: `{women/clothing/tops}` },
  //             { name: 'Dresses', id:"women_dress", href: '#' },
  //             { name: 'Women Jeans', id: 'women_jeans' },
  //             { name: 'Lengha Choli', id: 'lengha_choli' },
  //             { name: 'Sweaters', id: 'sweater' },
  //             { name: 'T-Shirts', id: 't-shirt' },
  //             { name: 'Jackets', id: 'jacket' },
  //             { name: 'Gouns', id: 'gouns' },
  //             { name: 'Sarees', id: 'saree' },
  //             { name: 'Kurtas', id: 'kurtas' },
  //             { name: 'Kurtas', id: 'kurtas' },
  //             { name: 'Shirt', id: 'shirt' },
  //           ],
  //         },
  //         // {
  //         //   id: 'accessories',
  //         //   name: 'Accessories',
  //         //   items: [
  //         //     { name: 'Watches', id: 'watch' },
  //         //     { name: 'Wallets', id: 'wallet' },
  //         //     { name: 'Bags', id: 'bag' },
  //         //     { name: 'Sunglasses', id: 'sunglasse' },
  //         //     { name: 'Hats', id: 'hat' },
  //         //     { name: 'Belts', id: 'belt' },
  //         //   ],
  //         // },
  //         // {
  //         //   id: 'brands',
  //         //   name: 'Brands',
  //         //   items: [
  //         //     { name: 'Full Nelson', id: '#' },
  //         //     { name: 'My Way', id: '#' },
  //         //     { name: 'Re-Arranged', id: '#' },
  //         //     { name: 'Counterfeit', id: '#' },
  //         //     { name: 'Significant Other', id: '#' },
  //         //   ],
  //         // },
  //       ],
  //     },
  //     // {
  //     //   id: 'men',
  //     //   name: 'Men',
  //     //   featured: [
  //     //     {
  //     //       name: 'New Arrivals',
  //     //       id: '#',
  //     //       imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg',
  //     //       imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
  //     //     },
  //     //     {
  //     //       name: 'Artwork Tees',
  //     //       id: '#',
  //     //       imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg',
  //     //       imageAlt:
  //     //         'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
  //     //     },
  //     //   ],
  //     //   sections: [
  //     //     {
  //     //       id: 'clothing',
  //     //       name: 'Clothing',
  //     //       items: [
  //     //         { name: 'Mens Kurtas', id: 'mens_kurta' },
  //     //         { name: 'Shirt', id: 'shirt' },
  //     //         { name: 'Men Jeans', id: 'men_jeans' },
  //     //         { name: 'Sweaters', id: '#' },
  //     //         { name: 'T-Shirts', id: 't-shirt' },
  //     //         { name: 'Jackets', id: '#' },
  //     //         { name: 'Activewear', id: '#' },
              
  //     //       ],
  //     //     },
  //     //     {
  //     //       id: 'accessories',
  //     //       name: 'Accessories',
  //     //       items: [
  //     //         { name: 'Watches', id: '#' },
  //     //         { name: 'Wallets', id: '#' },
  //     //         { name: 'Bags', id: '#' },
  //     //         { name: 'Sunglasses', id: '#' },
  //     //         { name: 'Hats', id: '#' },
  //     //         { name: 'Belts', id: '#' },
  //     //       ],
  //     //     },
  //     //     {
  //     //       id: 'brands',
  //     //       name: 'Brands',
  //     //       items: [
  //     //         { name: 'Re-Arranged', id: '#' },
  //     //         { name: 'Counterfeit', id: '#' },
  //     //         { name: 'Full Nelson', id: '#' },
  //     //         { name: 'My Way', id: '#' },
  //     //       ],
  //     //     },
  //     //   ],
  //     // },
  //   ],
  //   pages: [
  //     // { name: 'Company', id: '/' },
  //     // { name: 'Stores', id: '/' },
  //   ],
  // }