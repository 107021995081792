import React from 'react';

const Policies = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      {/* <h1 style={{ color: '#333', textAlign: 'center' }}>Terms and Conditions</h1> */}

      <section style={{ marginBottom: '20px' }}>
        <h2 style={{ color: '#2c3e50', fontSize: '18px', fontWeight:'700' }}>Terms and Conditions</h2>
        <p>
          By accessing or using this website, you agree to abide by the terms and conditions outlined
          below. Failure to comply with these terms may result in legal actions. The
          content, products, and services on this website are provided “as is”{' '}
          without warranties of any kind. Users are responsible for maintaining the{' '}
          confidentiality of their account details.
        </p>
      </section>

      <section style={{ marginBottom: '20px' }}>
        <span style={{ color: '#2c3e50', fontSize: '18px', fontWeight:'700' }}>Privacy Policy</span>
        <p>
          We are committed to protecting your privacy. We collect personal
          information such as name, email, and contact details for the purpose of
          providing services. This data is stored securely and will not be shared with third parties
          except as required by law. Cookies are used to improve user experience.
        </p>
      </section>

      <section style={{ marginBottom: '20px' }}>
        <span style={{ color: '#2c3e50', fontSize: '18px', fontWeight:'700' }}>Refund and Cancellation Policy</span>
        <p>
          Our application does not offer any refunds or cancellations. All sales
          are final. Please review your order carefully before completing the purchase.
        </p>
      </section>

      <section style={{ marginBottom: '20px' }}>
        <span style={{ color: '#2c3e50', fontSize: '18px', fontWeight:'700' }}>Pricing</span>
        <p>
          All prices listed on the website are in Indian Rupees (INR) and include
          applicable taxes. Prices are subject to change without prior notice.
        </p>
      </section>

      <section style={{ marginBottom: '20px' }}>
        <span style={{ color: '#2c3e50', fontSize: '18px', fontWeight:'700' }}>Shipping Policy</span>
        <p>
          Orders will be processed within 2 business days of payment confirmation.
          Shipping timelines vary depending on the location:
        </p>
        <ul>
          <li>
            Metro cities: 3-5 business days
          </li>
          <li>
            Other regions: 5-7 business days
          </li>
        </ul>
        <p>
          We also offer express shipping options at an additional cost.
        </p>
      </section>

      <section style={{ marginBottom: '20px' }}>
        <span style={{ color: '#2c3e50', fontSize: '18px', fontWeight:'700' }}>Contact Us</span>
        <p>
          For any queries, feel free to reach out to us:
          <br />
          <strong>Email</strong>: aabagarments522@gmail.com
          <br />
          <strong>Phone</strong>: +91-9744 557277
          <br />
          <strong>Address</strong>: AABA GARMENTS, Ernakulam, KE, 682006
        </p>
      </section>

      {/* <section style={{ marginBottom: '20px' }}>
        <span style={{ color: '#2c3e50', fontSize: '18px', fontWeight:'700' }}>Products and Services</span>
        <p>
          We offer a wide range of products, including <strong>[Product A, Product B]</strong>.
          Detailed descriptions, pricing, and terms specific to each product are available on the
          product pages. Services include <strong>[Service A, Service B]</strong>, and all services
          are governed by these policies.
        </p>
      </section> */}
    </div>
  );
};

export default Policies;
