// AuthGuard.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ element: Component, roles }) => {
    const loggedInUserData = JSON.parse(localStorage.getItem('AG_USER_INFO'));
    if (!loggedInUserData) {
        // If not logged in, redirect to unauthorized page
        return <Navigate to="/" />;
    }

    if (loggedInUserData?.role !== 'ADMIN') {
        // If user does not have the required role, redirect to unauthorized page
        return <Navigate to="/unauthorized" />;
    }

    // If user is authenticated and has the required role, render the component
    return <Component />;
};

export default AuthGuard;
