
import axios from 'axios';
const DEPLOYED='https://pear-poised-hen.cyclic.app/'
// const LOCALHOST='http://localhost:5454/.netlify/functions/app'
// const LOCALHOST='https://aaba-back-end.onrender.com/'
// const LOCALHOST='http://localhost:8888/.netlify/functions/app'

const LOCALHOST='https://aabagarments.netlify.app/.netlify/functions/app'

export const API_BASE_URL = LOCALHOST;

const api = axios.create({
  baseURL: API_BASE_URL,
});

const token = localStorage.getItem('jwt');

api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

api.defaults.headers.post['Content-Type'] = 'application/json';

export default api;
