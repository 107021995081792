import { Route, Routes } from 'react-router-dom';
import './App.css';
// import Navigation from './customer/Components/Navbar/Navigation';
import CustomerRoutes from './Routers/CustomerRoutes';
// import AdminRoutes from './Routers/AdminRoutes';
// import NotFound from './Pages/Notfound';
import AdminPannel from './Admin/AdminPannel';
// import Routers from './Routers/Routers';
import AuthGuard from './Routers/AuthGuard';
import UnauthorizedPage from './Routers/Unauthorized';
import Policies from './config/policies'
function App() {
  // const isAdmin=true;
  return (
    <div className="">
      
      <Routes>
        <Route path="/*" element={<CustomerRoutes />} />
        {/* <Route path="/admin/*" element={<AdminPannel />} /> */}
        <Route path="/admin/*" element={<AuthGuard element={AdminPannel} roles={['ADMIN']} />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/policies" element={<Policies/>} />
      </Routes>
    </div>
  );
}

export default App;