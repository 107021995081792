export const AdminCred = {
    _id: "admin123",
    firstName: "Aaba",
    lastName: "Garments",
    password: "Aaba123$$",
    email: "aabagarments@gmail.com",
    role: "ADMIN",
    paymentInformation: [],
    ratings: [],
    reviews: [],
    createdAt: "2024-08-03T10:17:37.256Z",
    __v: 3
};
