// import React, { useState } from "react";
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { storage } from "../config/firebaseConfig"; // Import the Firebase storage instance
// function FileUpload() {
//   const [file, setFile] = useState(null);
//   const [downloadURL, setDownloadURL] = useState("");

//   // Handle file selection
//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };

//   // Handle file upload
//   const handleUpload = async () => {
//     if (!file) return;
//     function generateRandomKey() {
//         const dateTime = new Date().getTime(); // Current time in milliseconds
//         const randomChars = Math.random().toString(36).substring(2, 7); // Random 5-letter string
//         return `${dateTime}-${randomChars}`;
//     }
      
//     // Usage
//     const randomKey = generateRandomKey();
//     console.log(randomKey); // Example output: 1698320490354-h1a9z
      
//     // Create a unique file name
//     const fileKey = `uploads/${randomKey}_${file.name}`;
//     const storageRef = ref(storage, fileKey);

//     try {
//       // Upload the file
//       await uploadBytes(storageRef, file);
//       console.log("File uploaded successfully");

//       // Get and set the download URL
//       const url = await getDownloadURL(storageRef);
//       setDownloadURL(url);
//       console.log("File available at", url);
//     } catch (error) {
//       console.error("Error uploading file:", error);
//     }
//   };

//   return (
//     <div>
//       <input type="file" onChange={handleFileChange} />
//       <button onClick={handleUpload}>Upload</button>
//       {downloadURL && (
//         <p>
//           File URL: <a href={downloadURL} target="_blank" rel="noopener noreferrer">{downloadURL}</a>
//         </p>
//       )}
//     </div>
//   );
// }

// export default FileUpload;










// import React, { useState, useEffect } from "react";
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { storage } from "../config/firebaseConfig"; // Import the Firebase storage instance

// function FileUpload({ onUpload }) { // Accepting onUpload as a prop
//   const [file, setFile] = useState(null);
//   const [downloadURL, setDownloadURL] = useState("");

//   // Handle file selection
//   const handleFileChange = async (event) => {
//     const selectedFile = event.target.files[0];
//     setFile(selectedFile);

//     // Trigger upload on file selection
//     if (selectedFile) {
//       await handleUpload(selectedFile);
//     }
//   };

//   // Handle file upload
//   const handleUpload = async (file) => {
//     function generateRandomKey() {
//       const dateTime = new Date().getTime(); // Current time in milliseconds
//       const randomChars = Math.random().toString(36).substring(2, 7); // Random 5-letter string
//       return `${dateTime}-${randomChars}`;
//     }

//     const randomKey = generateRandomKey();
//     const fileKey = `uploads/${randomKey}_${file.name}`;
//     const storageRef = ref(storage, fileKey);

//     try {
//       // Upload the file
//       await uploadBytes(storageRef, file);
//       console.log("File uploaded successfully");

//       // Get and set the download URL
//       const url = await getDownloadURL(storageRef);
//       setDownloadURL(url);
//       console.log("File available at", url);

//       // Pass the uploaded URL to the parent component
//       if (onUpload) {
//         onUpload(url);
//       }
//     } catch (error) {
//       console.error("Error uploading file:", error);
//     }
//   };

//   // Preview the uploaded image
//   const previewImage = () => {
//     if (file) {
//       return URL.createObjectURL(file);
//     }
//     return null;
//   };

//   return (
//     <div>
//       <input type="file" onChange={handleFileChange} />
//       {previewImage() && (
//         <img 
//           src={previewImage()} 
//           alt="File Preview" 
//           style={{ width: "200px", height: "auto", marginTop: "10px" }} 
//         />
//       )}
//       {downloadURL && (
//         <p>
//           File URL: <a href={downloadURL} target="_blank" rel="noopener noreferrer">{downloadURL}</a>
//         </p>
//       )}
//     </div>
//   );
// }

// export default FileUpload;



import React, { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../config/firebaseConfig"; // Import the Firebase storage instance

function FileUpload({ onUpload }) { // Accepting onUpload as a prop
  const [file, setFile] = useState(null);
  const [downloadURL, setDownloadURL] = useState("");

  // Handle file selection
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      await handleUpload(selectedFile);
    }
  };

  // Handle drag-and-drop
  const handleDrop = async (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      await handleUpload(droppedFile);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Handle file upload to Firebase
  const handleUpload = async (file) => {
    function generateRandomKey() {
      const dateTime = new Date().getTime(); // Current time in milliseconds
      const randomChars = Math.random().toString(36).substring(2, 7); // Random 5-letter string
      return `${dateTime}-${randomChars}`;
    }

    const randomKey = generateRandomKey();
    const fileKey = `uploads/${randomKey}_${file.name}`;
    const storageRef = ref(storage, fileKey);

    try {
      // Upload the file
      await uploadBytes(storageRef, file);
      console.log("File uploaded successfully");

      // Get and set the download URL
      const url = await getDownloadURL(storageRef);
      setDownloadURL(url);
      console.log("File available at", url);

      // Pass the uploaded URL to the parent component
      if (onUpload) {
        onUpload(url);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  // Preview the uploaded image
  const previewImage = () => {
    return file ? URL.createObjectURL(file) : null;
  };

  return (
    <div
      onClick={() => document.getElementById("fileInput").click()} // Open file picker when clicking anywhere on the div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="relative"
      style={{
        width: '300px',
        height: '200px',
        border: '2px dashed #999',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: '#555',
        cursor: 'pointer',
      }}
    >
      <div className="absolute flex flex-col items-center justify-center" style={{ color: "#fff",backgroundColor: "#48484863",width: "300px",height: "200px",  }}>
        <p>Drag & Drop to Upload Product Image</p>
        <p>OR</p>
        <p style={{ margin: 0 }}>Browse File</p> {/* Removed button effect, now shows as normal text */}
      </div>

      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="fileInput"
      />

      {previewImage() && (
        <img 
          src={previewImage()} 
          alt="File Preview" 
          style={{ width: "200px", height: "auto", marginTop: "10px" }} 
        />
      )}

      {downloadURL && (
        <p style={{display: "none"}}>
          File URL: <a href={downloadURL} target="_blank" rel="noopener noreferrer">
            {downloadURL}
            </a>
        </p>
      )}
    </div>
  );
}

export default FileUpload;

