import slide_1 from "../../../assets/slide_1.jpg"
import slide_2 from "../../../assets/slide_2.jpg"
import slide_3 from "../../../assets/slide_3.jpg"
import slide_4 from "../../../assets/slide_4.jpg"

export const homeCarouselData=[
    {
        image:slide_1,
        path:"/women/clothing/womens_kurtie"
    },
    {
        image:slide_2,
        path:"/women/clothing/womens_kurtie"
    },
    {
        image:slide_3,
        path:"/women/clothing/womens_kurtie"
    },
    {
        image:slide_4,
        path:"/women/clothing/womens_kurtie"
    }

]

